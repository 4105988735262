<template>
    <div style="background-color: #ffffff;text-align: left;padding: 10px 10px;border-radius: 5px;">
        <div class="pageCenter text-left m-b-20">
            <div class="df-jc-s-b m-b-20">
                <el-button-group>
                    <el-button v-for="v of avaluate" :key="v.id" @click="setAvaluate(v.id)" :type="QueryFurnitureLibrary.avaluate==v.id?'primary':''">{{v.text}}</el-button>
                </el-button-group>
            </div>
            <div class="" v-if="commentSpuList.content">
                <el-table :data="commentSpuList.content" style="width: 100%">
                    <el-table-column align="center" prop="date" label="评价星级" width="180">
                        <template slot-scope="scope">
                            <el-rate class="m-t-10" disabled v-model="scope.row.commentEvaluate"></el-rate>
                        </template>
                    </el-table-column>
                    <el-table-column align="left" prop="name" label="评论内容" width="360">
                        <template slot-scope="scope">
                            <div>{{scope.row.commentContent}}</div>
                            <my-media :srcList="$oucy.getSrcList(scope.row.commentAttachDtoList,'attachUrl')"></my-media>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="address" label="商家">
                        <template slot-scope="scope">
                            <div class="df-ai-c">
                                <img src="@/assets/logo.png" class="logo">
                                爱蒙仕品牌家具有限公司
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="left" prop="address" label="订单信息">
                        <template slot-scope="scope">
                            <div v-for="(v,i) of scope.row.orderSchemeSpus" :key="i">
                                <div class="bold c-1">{{v.spuName}}</div>
                                <div class="c-2">规格：套餐2 6人位 4+2 浅灰色带靠垫抱枕吉祥如意抱枕</div>
                                <div class="c-m">￥{{v.productPayfee}}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="address" label="操作">
                        <template slot-scope="scope">
                        <el-button @click="del(scope.row)" size="mini">删除评价</el-button>
                    </template>
                    </el-table-column>
                </el-table>
            </div>
            <div v-else class="p-50 c-2 text-center">还没有评论</div>
        </div>
        <div class="pagination" style="background-color: #ffffff;padding: 20px 0;">
            <el-pagination class="m-t-10" v-if="commentSpuList.content" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="commentSpuList.size" :total="commentSpuList.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
            </el-pagination>
        </div>
        <!-- <div ref="myLoginQrCodeUrl"></div> -->
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { schemeOrder, spuComment } from '@/service/index.js'
import { localSet, localGet, localDel } from "@/store/store"
import QRCode from 'qrcodejs2'
export default {
    name: "Enter",
    data() {
        return {
            // 按钮组 星级 1非常差 2差评 3一般 4好评 5非常好
            avaluate: [
                { text: '全部', id: null },
                { text: '非常好', id: 5 },
                { text: '好评', id: 4 },
                { text: '一般', id: 3 },
                { text: '差评', id: 2 },
                { text: '非常差', id: 1 },
            ],
            // 请求对象
            QueryFurnitureLibrary: {
                avaluate: 5,
                limit: 20,
                start: 0,
                orderSchemeSpuSortEnum: 0, //0 最新排序 1默认排序
                spuId: null
            },
            commentSpuList: {},
        }
    },
    computed: {

    },
    mounted() {
        this.getCommentSpuList()
    },
    methods: {
        // 请求列表
        getCommentSpuList() {
            spuComment.getCommentSpuList(this.QueryFurnitureLibrary).then(res => {
                this.secondText = `商品评价(${res.totalElements})`
                this.commentSpuList = res
            })
        },

        // 切换页数
        sizeChange(pageSize) {
            this.QueryFurnitureLibrary.limit = pageSize;
            this.getCommentSpuList();
        },
        // 切换页码
        currentChange(current) {
            this.QueryFurnitureLibrary.start = current - 1;
            this.getCommentSpuList();
        },
        // 切换评分
        setAvaluate(v) {
            this.QueryFurnitureLibrary.avaluate = v
            this.getCommentSpuList();
        },
        del(v){
            this.$confirm('确认删除评论！', '删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                console.log('点了确认')
            }).catch(() => {
                console.log('点了取消')
            });
        },
    }
}
</script>
<style scoped lang="less">
.titleBar {
    height: 100px;
    /*background-color: #0366C3;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.titleBar_left {
    /*background-color: #42b983;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.titleBar_left_txt {
    /*background-color: #409EFF;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    font-size: 25px;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px #eeeeee solid;
    color: gray;
}

.titleBar_right {
    font-size: 25px;
    color: gray;
}

.titleBar_right:hover {
    cursor: pointer;
    color: #000000;
}

.breadcrumbBar {
    padding: 10px 0;
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.breadcrumbBar.enterHeadWidth {
    padding: 0 60px;
}

.el-menu-vertical-demo {
    text-align: left;
    min-height: 700px;
    border-radius: 4px;
}

.el-menu-item,
.el-submenu__title {
    font-size: 16px;
}

.el-menu-item span {
    font-size: 16px;
}

.dialogImg {
    width: 104px;
    height: 104px;
    margin-right: 10px;
    margin-top: 10px;
}

.btn {
    line-height: 1.2;
    display: inline-block;
    padding: 6px 36px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #EAEAEA;
    position: relative;
    cursor: pointer;
    vertical-align: middle;

    .corner {
        position: absolute;
        right: 0;
        bottom: 0;
        display: none;
    }

    &.select {
        border: 1px solid #2090FF;
        color: #2090ff;

        .corner {
            display: inline-block;
        }
    }

    .btnImg {
        vertical-align: inherit;
    }
}

.btn+.btn {
    margin-left: 3px;
}

.logo {
    width: 27px;
    height: 27px;
}
</style>